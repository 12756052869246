import Image from 'next/image'

import SpinnerLoader from './shared/SpinnerLoader'
import {signOut, useSession} from 'next-auth/react'
import {useTranslation} from 'next-i18next'
import StoryblokClient from 'storyblok-js-client'
import {AssetStoryblok} from 'types/storyblok-types'

type AuthErrorType = {
  image: AssetStoryblok
  text: {
    content: {content: {text: string; type: string}[]}[]
  }
  title: string
}

const AuthError = ({image, text, title}: AuthErrorType) => {
  const {data, status} = useSession()
  const {t} = useTranslation()

  const Storyblok = new StoryblokClient({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_KEY!,
  })
  const infoHtml = Storyblok.richTextResolver.render(text)
  return (
    <>
      <div className="relative flex h-screen items-center justify-center bg-dats-blue-border text-center">
        <div className="absolute left-0 top-0 h-screen w-screen">
          <Image
            src={image.filename}
            alt={'dats24-background_image'}
            width="0"
            height="0"
            sizes="100vw"
            style={{minWidth: '100vw', height: '100vh', objectFit: 'cover'}}
          />
        </div>
        {status === 'authenticated' &&
        !data?.extension_HasAccessToDATS24B2BMobilityPortal ? (
          <div className="relative flex w-4/5 flex-col items-start justify-center rounded bg-white px-10 pb-10 pt-16 md:h-3/5 lg:w-4/12">
            <h2 className="mb-4 text-left text-4xl font-bold">{title}</h2>
            <p
              dangerouslySetInnerHTML={{__html: infoHtml}}
              className="text-left text-lg [&_a]:text-blue-500 [&_a]:underline"
            />
            <button
              onClick={() => signOut({redirect: false})}
              className="absolute left-4 top-4 flex items-center justify-center rounded-md py-3 font-rubik font-bold text-dats-green"
            >
              <svg
                width="30"
                height="20"
                viewBox="0 0 320 512"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path
                  id="Path"
                  fill="#005e75"
                  stroke="none"
                  d="M 34.523342 272.971008 L 228.867355 467.313995 C 238.240356 476.687012 253.436356 476.687012 262.80835 467.313995 L 285.475342 444.647003 C 294.832367 435.290009 294.850342 420.125 285.51535 410.746002 L 131.494354 256 L 285.51535 101.255005 C 294.850342 91.876007 294.832367 76.710999 285.475342 67.354004 L 262.80835 44.687012 C 253.435349 35.313995 238.239349 35.313995 228.867355 44.687012 L 34.524349 239.029999 C 25.151363 248.401978 25.151363 263.597992 34.523342 272.971008 Z"
                />
              </svg>
              {t('log_out')}
            </button>
          </div>
        ) : (
          <SpinnerLoader heightClassName="h-16" widthClassName="h-16" />
        )}
      </div>
    </>
  )
}

export default AuthError
