import {signOut} from 'next-auth/react'
import Image from 'next/image'
import {useTranslation} from 'next-i18next'
import analytics from 'analytics'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'

type LogoutItemProps = {
  className?: string
  lockDesktopLayout?: boolean
}

const LogoutItem = ({className, lockDesktopLayout}: LogoutItemProps) => {
  const {t} = useTranslation('common')
  const customerContext = useCustomerAffiliateContext()

  const handleSignOut = () => {
    analytics.sendUserLoggedOutEvent(
      'no',
      customerContext.currentUser?.digitalServiceUserId,
    )
    signOut()
  }

  const lockDesktop = () => {
    return lockDesktopLayout ?? false
  }

  return (
    <div
      onClick={() => handleSignOut()}
      className={`flex h-[50px] w-full cursor-pointer items-center justify-between border-dats-gray-border md:border-b md:border-none ${
        className ?? ''
      }`}
    >
      <div className="flex items-center">
        <div
          className={`mr-4 ${
            lockDesktop() ? '' : 'hidden'
          } size-4 justify-center md:block`}
        >
          <Image
            className="h-4"
            src="/images/icon_sign_out_alt.svg"
            alt={t('log_out')}
            width={24}
            height={24}
            unoptimized={true}
          />
        </div>

        <div className="font-body font-semibold text-dats-red">
          {t('log_out')}
        </div>
      </div>

      <div className={`${lockDesktop() ? 'hidden' : 'block'} md:hidden`}>
        <Image
          className="h-4"
          src="/images/icon_chevron_right.svg"
          alt=""
          width={24}
          height={24}
          unoptimized={true}
        />
      </div>
    </div>
  )
}

export default LogoutItem
